/* Contêiner da grade */
.grid-container {
  display: grid;
  grid-template-columns: repeat(6, 1fr); /* 4 colunas */
  gap: 16px; /* Espaçamento entre os itens */
  padding: 16px; /* Espaçamento interno */
  background-color: #f8f9fa; /* Cor de fundo opcional */
}

/* Componente personalizado */
.custom-component {
  background-color: #ffffff; /* Fundo branco */
  border: 1px solid #ddd; /* Borda cinza clara */
  border-radius: 8px; /* Bordas arredondadas */
  padding: 16px; /* Espaçamento interno */
  text-align: center; /* Centralizar texto */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Sombra suave */
  transition: transform 0.2s ease, box-shadow 0.2s ease; /* Animação ao passar o mouse */
}

/* Efeito ao passar o mouse */
.custom-component:hover {
  transform: translateY(-4px); /* Eleva o item */
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15); /* Intensifica a sombra */
}
